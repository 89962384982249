import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Link = ({ to, children }) => <a target="_blank" rel="noopener noreferrer" href={to}>{children}</a>

const SecondPage = () => (
  <Layout>
    <Seo title="Contact" descriptions="Ways to find me on the web" />
    <h1>Contact</h1>
    <p>Find me on the web</p>
    <p>Twitter: <Link to="https://twitter.com/yagudaev">@yagudaev</Link></p>
    <p>Github: <Link to="https://github.com/yagudaev">@yagudaev</Link></p>
    <p>Linkin: <Link to="https://linkedin.com/in/yagudaev">Michael Yagudaev</Link></p>
  </Layout>
)

export default SecondPage
